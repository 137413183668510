<template>
  <div id="card">
    <el-dialog
      :title="cardFormTitle"
      width="360px"
      :visible.sync="cardDialogVisible"
      :close-on-click-modal="false"
      @close="cardDialogClose"
    >
      <el-form
        ref="cardFormRef"
        :model="cardForm"
        :rules="cardFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="审核状态" prop="campOwnerStatus">
          <el-select v-model="cardForm.campOwnerStatus" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button @click="cardDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="cardFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-row :gutter="20">
      <el-form inline size="small">
        <el-form-item label="认领状态选择" prop="campOwnerStatus">
          <el-select v-model="searchForm.campOwnerStatus" placeholder="请选择认领状态">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="handleSearch">
            搜索
          </el-button>
        </el-form-item>
      </el-form>

      <el-table
        ref="cardTableRef"
        :data="cardPage.list"
        :tree-props="{children: 'children'}"
        row-key="id"
        default-expand-all
        :cell-style="{padding: '6px'}"
        :header-cell-style="{background: '#f8f8f9'}"
        :height="getTableHeightNoPage()"
        @row-click="handleRowClick"
      >
        <el-table-column prop="name" label="名称" />
        <el-table-column
          label="营业执照"
          prop="businessLicense"
          align="center"
          width="100px"
        >
          <template slot-scope="scope">
            <el-image
              style="width: 50px; height: 50px; margin-bottom:-4px"
              :src="`https://quye.zpsoftware.cn/api/files/${scope.row.businessLicense}`"
              :preview-src-list="srcList"
              @click="
                bigImg(`https://quye.zpsoftware.cn/api/files/${scope.row.businessLicense}`)
              "
            />
          </template>
        </el-table-column>
        <el-table-column
          label="身份证正面"
          prop="cardFront"
          align="center"
          width="100px"
        >
          <template slot-scope="scope">
            <el-image
              style="width: 50px; height: 50px; margin-bottom:-4px"
              :src="`https://quye.zpsoftware.cn/api/files/${scope.row.cardFront}`"
              :preview-src-list="srcList"
              @click="
                bigImg(`https://quye.zpsoftware.cn/api/files/${scope.row.cardFront}`)
              "
            />
          </template>
        </el-table-column>
        <el-table-column
          label="身份证正面"
          prop="cardBack"
          align="center"
          width="100px"
        >
          <template slot-scope="scope">
            <el-image
              style="width: 50px; height: 50px; margin-bottom:-4px"
              :src="`https://quye.zpsoftware.cn/api/files/${scope.row.cardBack}`"
              :preview-src-list="srcList"
              @click="
                bigImg(`https://quye.zpsoftware.cn/api/files/${scope.row.cardBack}`)
              "
            />
          </template>
        </el-table-column>
        <el-table-column prop="campOwnerStatus" label="状态" />
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="checkPermission(['DEPT_UPDATE'])"
              type="text"
              icon="el-icon-edit"
              size="small"
              @click.stop="cardExamine(scope.$index, scope.row)"
            >
              审核
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :total="cardPage.total"
        :current-page="searchForm.pageNum"
        :page-size="searchForm.pageSize"
        :page-sizes="[10, 15, 20]"
        layout="total, sizes, prev, pager, next, jumper"
        background
        @current-change="pageNumChange"
        @size-change="pageSizeChange"
      />
    </el-row>
  </div>
</template>

<script>
import {
  selectCardList,
  selectDeptInfo,
  selectUserList,
  selectCardInfo,
  cardExamine
} from '@/api/business/idcard'

export default {
  data () {
    return {
      cardDialogVisible: false,
      cardFormTitle: '',
      cardList: [],
      cardForm: {
        id: '',
        name: '',
        campOwnerStatus: ''
      },
      srcList: [''],

      cardPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        id: '',
        campOwnerStatus: '',
        name: ''
      },
      options: [{
        value: '1',
        label: '通过'
      }, {
        value: '-1',
        label: '未通过'
      }],
      options1: [{
        value: '1',
        label: '已认领'
      }, {
        value: '0',
        label: '未认领'
      }],
      value: '',
      cardTree: [],
      cardFormRules: {
        campOwnerStatus: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}]
      }
    }
  },
  created () {
    selectCardList(this.searchForm).then(res => {
      this.cardPage = res
    })
    selectCardpTree().then(res => {
      this.cardTree = res
    })
  },
  methods: {
    CardDialogClose () {
      this.$refs.cardFormRef.resetFields()
    },
    bigImg (url) {
      this.srcList[0] = url
    },
    cardFormSubmit () {
      this.$refs.cardFormRef.validate(async valid => {
        console.log('2123' + valid)
        if (valid) {
          console.log('2123' + this.cardFormTitle === '营地审核')
          if (this.cardFormTitle === '营地审核') {
            console.log('546')
            await cardExamine(this.cardForm)
          }
          this.carduserPage = await selectCardList(this.searchForm)
          this.cardDialogVisible = false
        }
      })
    },
    handleRowClick (row, column, event) {
      this.$refs.cardTableRef.toggleRowExpansion(row)
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectCardList(this.searchForm).then(res => {
        this.cardPage = res
      })
    },
    handleUpdate (index, row) {
      this.cardFormTitle = '营地审核'
      this.cardDialogVisible = true
      this.selectCardInfoById(row.id)
    },
    cardExamine (index, row) {
      this.cardFormTitle = '营地审核'
      this.cardDialogVisible = true

      this.selectCardInfoById(row.id)
    },
    selectCardInfoById (id) {
      selectCardInfo(id).then(res => {
        this.cardForm.id = res.id
      })
    },
    userFormSubmit () {
      if (this.cardFormTitle === '用户详情') {
        this.cardDialogVisible = false
        return
      }
      this.$refs.cardFormRef.validate(async valid => {
        if (valid) {
          this.cardForm.roleIds = this.cardForm.roleIds.join(',')
          if (thiscardFormTitle === '营地审核') {
            await cardExamine(this.cardForm)
          }
          this.cardPage = await selectCardList(this.searchForm)
          this.cardDialogVisible = false
        }
      })
    },
    cardTreeNodeClick (data) {
      this.searchForm.campOwnerStatus = data.campOwnerStatus
      this.searchForm.pageNum = 1
      selectCardList(this.searchForm).then(res => {
        this.cardPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectCardList(this.searchForm).then(res => {
        this.cardPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectCardList(this.searchForm).then(res => {
        this.cardPage = res
      })
    }
  }
}
</script>

<style>
.el-image-viewer__mask {
    background: none !important;
  }
  .el-image-viewer__close {
    top: 40px;
    right: 40px;
    width: 40px;
    height: 40px;
    font-size: 24px;
    color: #fff;
    background-color:rgba(255, 255, 255, 0)!important;
}
</style>
