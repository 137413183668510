import axios from '@/common/axios'
import qs from 'qs'

export function cardExamine (data) {
  return axios({
    method: 'put',
    url: '/camp/campOwnerAudit',

    data: qs.stringify(data)
  })
}

export function selectCardInfo (id) {
  return axios({
    method: 'get',
    url: '/camp/campInfo/' + id
  })
}

export function selectCardList (query) {
  return axios({
    method: 'get',
    url: '/camp/list/',
    params: query
  })
}





